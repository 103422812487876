import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    root: {
      margin: 'auto',
      width: '500px',
      marginTop: '14px',
      marginBottom: '14px',
      paddingBottom: '20px',
      border: '1px solid #2596be',
      borderRadius: '20px',
    },
    header: {
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
      paddingTop: '6px',
      paddingBottom: '6px',
      backgroundColor: '#2596be',
      color: 'white',
      textAlign: 'center',
      fontSize: '16px',
      textShadow: '1px 1px 2px black',
    },
    fieldsArea: {
      margin: '20px 50px 0px 50px',
    },
    confirmation: {
      textAlign: 'center',
    },
  },
  {
    name: 'AuthForm',
  },
);
