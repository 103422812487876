import * as React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { Notification, Spinner } from '@align-cpf/uikit';
import { createRequest, getFieldsRequest } from '../../../clients/repo';

import { AuthForm } from '../../molecules/AuthForm';
import { Buffer } from 'buffer';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { IStringFields } from '../../../types';
import { RepoSettingsForm } from '../../molecules';
import { getProjects } from '../../../clients/projects';
import { useGlobalContext } from '../../context';
import useStyles from './InstallationForm.styles';

export const InstallationForm: React.FC = () => {
  const classes = useStyles();
  const { appConfig } = useGlobalContext();

  const [fields, setFields] = React.useState<IStringFields>({});
  const [spinner, setSpinner] = React.useState(false);
  const [countDown, setCountDown] = React.useState(false);
  const [authorized, setAuthorized] = React.useState(false);
  const [basicAuth, setBasicAuth] = React.useState('');
  const [successNotification, setSuccessNotification] = React.useState({
    isShow: false,
    text: '',
  });
  const [errorNotification, setErrorNotification] = React.useState({
    isShow: false,
    text: '',
  });

  const renderTime = (dimension: any, time: any) => {
    return (
      <div className={classes.timerWrapper}>
        <div className={classes.time}>{time}</div>
        <div>{dimension}</div>
      </div>
    );
  };

  const seconds = 180;
  const getTimeSeconds = (time: any) => (seconds - time) | 0;

  const methods = useForm<IStringFields>({
    mode: 'onChange',
    defaultValues: { ...fields },
  });

  const onAuthSubmit = (data: IStringFields) => {
    setSpinner(true);
    const basicAuth = Buffer.from(
      `${data['username']}:${data['password']}`,
    ).toString('base64');
    getProjects(appConfig, basicAuth)
      .then(() => {
        setSpinner(false);
        setBasicAuth(basicAuth);
        setAuthorized(true);
      })
      .catch(async reason => {
        setSpinner(false);
        if (reason.status == 401) {
          setErrorNotification({ isShow: true, text: 'Invalid login or password' });
        }
        else {
          const jsonReason = await reason.text();
          setErrorNotification({ isShow: true, text: JSON.parse(jsonReason).message });
        }
      });
  };

  React.useEffect(() => {
    getFieldsRequest(appConfig)
      .then(fields => {
        setFields(fields);
      })
      .catch(reason => {
        reason.json().then((reason_json: { errorMessage: string }) => {
          setErrorNotification({
            isShow: true,
            text: reason_json.errorMessage,
          });
        });
      });
  }, [appConfig]);

  const onRepoSettingsSubmit = (data: IStringFields) => {
    delete data['username'];
    delete data['password'];
    setCountDown(true);
    createRequest(appConfig, basicAuth, data)
      .then(response => {
        setCountDown(false);
        setSuccessNotification({
          isShow: true,
          text:
            'Link to the repo: ' + response.repoLink + '. SSH: ' + response.ssh,
        });
      })
      .catch(reason => {
        setCountDown(false);
        reason.json().then((reason_json: { errorMessage: string }) => {
          setErrorNotification({
            isShow: true,
            text: reason_json.errorMessage,
          });
        });
      });
  };

  return (
    <div className={classes.root}>
      {successNotification.isShow && (
        <div className={classes.notification}>
          <Notification
            type={'success'}
            inline={true}
            delay={0}
            title={'Successful operation!'}
            message={
              'Repository was sucessfully created. ' + successNotification.text
            }
            onClose={() => setSuccessNotification({ isShow: false, text: '' })}
            multiline={true}
          />
        </div>
      )}
      {errorNotification.isShow && (
        <div className={classes.notification}>
          <Notification
            type={'error'}
            inline={true}
            delay={0}
            title={'Error!'}
            message={errorNotification.text}
            onClose={() => setErrorNotification({ isShow: false, text: '' })}
            multiline={true}
          />
        </div>
      )}
      {!authorized && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onAuthSubmit)}>
            <AuthForm />
          </form>
        </FormProvider>
      )}
      {(spinner || (authorized && !fields)) && (
        <div className={classes.spinner}>
          <Spinner size={96} />
        </div>
      )}
      {authorized && countDown && (
        <div className={classes.countDown}>
          <svg>
            <defs>
              <linearGradient id="gradientColor" x1="1" y1="0" x2="0" y2="0">
                <stop offset="15%" stopColor="#2596be" />
                <stop offset="95%" stopColor="green" />
                <stop offset="5%" stopColor="green" />
                <stop offset="95%" stopColor="green" />
              </linearGradient>
            </defs>
          </svg>
          <CountdownCircleTimer
            isPlaying
            duration={180}
            colors="url(#gradientColor)"
          >
            {({ elapsedTime, color }) => (
              <span style={{ color }}>
                {renderTime('Creating...', getTimeSeconds(elapsedTime))}
              </span>
            )}
          </CountdownCircleTimer>
        </div>
      )}
      {authorized && !spinner && !countDown && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onRepoSettingsSubmit)}>
            <RepoSettingsForm fields={fields} />
          </form>
        </FormProvider>
      )}
    </div>
  );
};
