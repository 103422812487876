import * as React from 'react';
import { IAppConfig } from '../../types';

interface IAppGlobalContext {
  appConfig: IAppConfig;
}

const AppGlobalContext = React.createContext<IAppGlobalContext>({
  // eslint-disable-next-line
  appConfig: null as any,
});

interface AppGlobalContextProviderProps {
  children: React.ReactNode;
  appConfig: IAppConfig;
}

export const AppGlobalContextProvider = ({
  children,
  appConfig,
}: AppGlobalContextProviderProps) => {
  return (
    <AppGlobalContext.Provider
      value={{
        appConfig,
      }}
    >
      {children}
    </AppGlobalContext.Provider>
  );
};

export const useGlobalContext = () => React.useContext(AppGlobalContext);
