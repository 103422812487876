import '@fontsource/roboto';

import { createUseStyles } from 'react-jss';

export default createUseStyles({
  '@global': {
    body: {
      margin: 0,
      padding: 0,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, ' +
        "'Segoe UI', 'Roboto', 'Oxygen'," +
        "'Ubuntu', 'Cantarell', 'Fira Sans', " +
        "'Droid Sans', 'Helvetica Neue', sans-serif",
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      backgroundColor: '#2F2F2F',
    },
    /* width */
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#6A6C6C'
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    },
  },
});
