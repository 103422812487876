import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    root: {
      height: '50px',
      position: 'fixed',
      width: '100%',
      backgroundColor: '#232323',
      display: 'flex',
      borderBottom: '1px solid #525252',
      zIndex: '100'
    },
    headerText: {
      color: 'white',
      margin: 'auto',
      fontSize: '24px',
      textShadow: '1px 1px 2px black',
    }
  },
  {
    name: 'Header',
  },
);
