import { Page } from '../components/templates/Page';
import React from 'react';
import useStyles from './App.styles';

export const App: React.FC = () => {
  useStyles();

  return (
    <div className="App">
      <Page />
    </div>
  );
};
