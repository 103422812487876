import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    root: {
    },
    spinner: {
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      width: '100%',
      filter: 'invert(49%) sepia(50%) saturate(688%) hue-rotate(150deg) brightness(90%) contrast(89%)'
    },
    notification: {
      position: 'fixed',
      right: '20px',
      top: '80px',
      zIndex: '100'
    },
    countDown: {
      position: 'absolute',
      zIndex: 100,
      top: '35%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    timerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      width: '80px',
      height: '60px',
      fontSize: '25px',
      color: 'white',
      fontFamily: 'Montserrat'
    },
    time: {
      position: 'absolute',
      left: 0,
      top: 20,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: 'translateY(0)',
      opacity: 1,
      transition: 'all 0.2s'
    }
  },
  {
    name: 'InstallationForm',
  },
);
