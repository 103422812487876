import { App } from './Core/App/App';
import { AppGlobalContextProvider } from './Core/components/context';
import { CssBaseline } from '@align-cpf/uikit';
import { IAppConfig } from './Core/types';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { getConfig } from './Core/helpers';

const GetRoot = () => {
  getConfig().then(config => {
    console.log('Actual config:');
    console.log(config);
    createApp(config);
  });
};

function createApp(config: IAppConfig) {
  return ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  ).render(
    <React.StrictMode>
      <CssBaseline theme="dark">
        <AppGlobalContextProvider appConfig={config}>
          <App />
        </AppGlobalContextProvider>
      </CssBaseline>
    </React.StrictMode>,
  );
}

GetRoot();
