import * as React from 'react';

import useStyles from './Header.styles';

export const Header: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.headerText}>
        10 Min BDD Installer
      </div>
    </div>
  );
};
