import { Header } from '../../molecules';
import { InstallationForm } from '../../organisms';
import React from 'react';
import useStyles from './Page.styles';

export const Page: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.mainContainer}>
        <InstallationForm />
      </div>
    </div>
  );
};
