import { IAppConfig } from '../types';
import defaultConfig from '../../config/config.json';

export async function getConfig(): Promise<IAppConfig> {
  return (await getDefaultConfig().then(() => ({
    ...defaultConfig,
  }))) as IAppConfig;
}

export async function getDefaultConfig(): Promise<IAppConfig> {
  const configUrl =
    // eslint-disable-next-line no-restricted-globals
    location.href.slice(0, location.href.lastIndexOf('/')) + '/config.json';
  console.log('config url:', configUrl);
  return await fetch(configUrl)
    .then(response => response.json())
    .catch(reason => {
      console.error(reason);
    });
}
