import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    mainContainer: {
      height: '100%',
      width: '100%',
      marginTop: '52px',
      marginBottom: '6px',
      borderRadius: '4px',
    },
  },
  {
    name: 'Page',
  },
);
