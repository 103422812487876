import * as React from 'react';

import { Button, Field, InputText } from '@align-cpf/uikit';
import { Controller, useFormContext } from 'react-hook-form';

import { IStringFields } from '../../../types';
import useStyles from './RepoSettingsForm.styles';

// eslint-disable-next-line
export const RepoSettingsForm: React.FC<any> = ({ fields }) => {
  const classes = useStyles();
  const { control } = useFormContext<IStringFields>();


  const defaultValidationMessage =
    'Please fill out this mandatory field to proceed'

  const capitalize = (str: string) => {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        Please enter configuration of your setup
      </div>
      <div className={classes.fieldsArea}>
        {Object.entries(fields).map(([name, value]) => (
          <div className={classes.field} key={name}>
            <Controller
              name={name}
              control={control}
              rules={{
                required: defaultValidationMessage,
              }}
              render={({ field, fieldState }) => (
                <Field label={capitalize(name.replaceAll('_', ' '))} 
                    required={true} 
                    invalid={Boolean(fieldState.error)}
                    helperText={'Example: ' + value as string}
                    errorMessage={fieldState.error?.message}>
                  <InputText {...field} placeholder={value as string}/>
                </Field>
              )}
            />
          </div>
        ))}
      </div>
      <div className={classes.confirmation}>
        <Button type="submit">Create repository</Button>
      </div>
    </div>
  );
};
