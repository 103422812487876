import { IAppConfig, IRepo, IStringFields } from '../types';

import URI from 'urijs';
import { handleJsonResponse } from './fetchHelpers';

export function createRequest(
    { apiUrl }: IAppConfig,
    basicAuth: string,
    fields: IStringFields
): Promise<IRepo> {
    return handleJsonResponse(
        fetch(`${apiUrl}/repo/create`, {
            method: 'POST',
            headers: { 'content-type': 'application/json', 'Authorization': `Basic ${basicAuth}` },
            body: JSON.stringify({...fields}),
        }),
    );
}

export function getFieldsRequest(
    { apiUrl }: IAppConfig,
) {
    return handleJsonResponse(
        fetch(
            URI(apiUrl)
                .segment('repo')
                .segment('fields')
                .toString(),
            {
                method: 'GET',
            },
        ),
    );
}