import * as React from 'react';

import { Button, Field, InputPassword, InputText } from '@align-cpf/uikit';
import { Controller, useFormContext } from 'react-hook-form';

import { IStringFields } from '../../../types';
import useStyles from './AuthForm.styles';

export const AuthForm: React.FC = () => {
  const classes = useStyles();
  const { control } = useFormContext<IStringFields>();

  return (
    <div className={classes.root}>
      <div className={classes.header}>Authorization</div>
      <div className={classes.fieldsArea}>
        <Controller
          name="username"
          control={control}
          rules={{
            required: 'Please your username',
          }}
          render={({ field, fieldState }) => (
            <Field 
              label={'Username'}                     
              required={true} 
              invalid={Boolean(fieldState.error)}
              errorMessage={fieldState.error?.message}>
              <InputText {...field} placeholder={'user@aligntech.com'} />
            </Field>
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{
            required: 'Please enter the password',
          }}
          render={({ field, fieldState }) => (
            <Field 
              label={'Password'}               
              required={true} 
              invalid={Boolean(fieldState.error)}
              errorMessage={fieldState.error?.message}>
              <InputPassword {...field} />
            </Field>
          )}
        />
      </div>
      <div className={classes.confirmation}>
        <Button type="submit">Enter</Button>
      </div>
    </div>
  );
};
