import { IAppConfig } from '../types';
import URI from 'urijs';
import { handleJsonResponse } from './fetchHelpers';

export function getProjects({ apiUrl }: IAppConfig, basicAuth: string) {
  return handleJsonResponse(
    fetch(URI(apiUrl).segment('projects').toString(), {
      method: 'GET',
      headers: { 'Authorization': `Basic ${basicAuth}` },
    }),
  );
}
